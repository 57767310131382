
























































































import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import {
    getQuestions,
    postMobileSave,
    postMobileManagement,
    getQinToken
} from '@/api/courseWork/myHomework/answerSheet.ts';
import axios from 'axios';
import router from '@/router';

Component.registerHooks([
    'beforeRouteLeave'
]);
@Component({
    components: {
        myHeader,
        myFooter
    }
})
export default class AnswerSheet extends Vue {
  // textAnswer: any;
  data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      ques_id: 0,
      // eslint-disable-next-line @typescript-eslint/camelcase
      semester_id: 0
  };

  // 题目数据
  questionsData = [{
      // eslint-disable-next-line @typescript-eslint/camelcase
      options: [{ is_selected: true }],
      // eslint-disable-next-line @typescript-eslint/camelcase
      has_answer: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      boolean_answer: true,
      // eslint-disable-next-line @typescript-eslint/camelcase
      text_answer: ''
  }];

  // 课程名字
  crsName = '';
  // 节名字
  sectionName = '';
  // 总题目数
  totalSubject = 0;
  // 已做多少题
  finishSubject = 0;
  // 缓存定时器
  mobileSaveTimer = 0;
  // 浮动题目标题状态
  titleFixed = true;
  // 选中描点
  // numberIndex = 0;
  // loadding状态
  loadingIndex = true;
  imgshowlist = [] as any [];
  selectedFile = '';

  mounted() {
      const scheckSemester = JSON.parse(localStorage.getItem('checkSemester') as string);
      if (scheckSemester != null) {
          this.data.semester_id = scheckSemester.id;
      } else {
          this.data.semester_id = parseInt(localStorage.getItem('semesterId') as string);
      }
      this.getData();
  }

  // 方法
  getData() {
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.data.ques_id = parseInt(this.$route.query.quesId as string);
      this.crsName = this.$route.query.crsName as string;
      this.sectionName = this.$route.query.sectionName as string;
      // eslint-disable-next-line @typescript-eslint/camelcase

      getQuestions(this.data).then((res: any) => {
          this.loadingIndex = false;
          this.questionsData = res.ques_list;
          this.totalSubject = res.total;
          this.mobileSaveTimer = setInterval(() => {
              this.mobileSave();
          }, 30000);
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 单选 多选
  changeChoice(index: number, subscript: number, type: string) {
      if (type === 'radio') {
          for (let i = 0; i < 4; i++) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[i].is_selected = false;
          }
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].options[subscript].is_selected = true;
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = true;
          this.countTopic();
      } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
          if (this.questionsData[index].options[subscript].is_selected) {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[subscript].is_selected = false;
          } else {
              // eslint-disable-next-line @typescript-eslint/camelcase
              this.questionsData[index].options[subscript].is_selected = true;
          }
          // 判断是否有做题
          // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = this.questionsData[index].options.some((item) => {
              return item.is_selected;
          });
          this.countTopic();
      }
  }

  // 判断题
  changeBoolean(index: number, answer: boolean) {
      // 节流
      if (answer === this.questionsData[index].boolean_answer) {
          return;
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.questionsData[index].has_answer = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      this.questionsData[index].boolean_answer = answer;
      this.countTopic();
  }

  // 其他题目
  mouseLeave(index: number) {
      if (this.questionsData[index].text_answer.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = false;
      } else {
      // eslint-disable-next-line @typescript-eslint/camelcase
          this.questionsData[index].has_answer = true;
      }
      this.countTopic();
  }

  // 定时缓存题目
  mobileSave() {
      const mobileSaveData = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          ques_id: this.data.ques_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          semester_id: this.data.semester_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          question_list: this.questionsData,
          type: 0
      };
      postMobileSave(mobileSaveData).then((res: any) => {
          console.log(res);
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 跳转到答题报告
  goAnswerReport() {
      const mobileManagementData = {
      // eslint-disable-next-line @typescript-eslint/camelcase
          ques_id: this.data.ques_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          semester_id: this.data.semester_id,
          // eslint-disable-next-line @typescript-eslint/camelcase
          question_list: this.questionsData
      };
      postMobileManagement(mobileManagementData).then((res: any) => {
          console.log(res);
          clearInterval(this.mobileSaveTimer);
          this.$router.push({
              path: '/answerReport',
              query: {
                  ansId: res.ans_id,
                  crsName: this.crsName,
                  sectionName: this.sectionName,
                  crsId: this.$route.query.crsId as string,
                  totalQues: this.$route.query.totalQues as string
              }
          });
      }).catch((error: any) => {
          console.log(error);
      });
  }

  // 计算题目
  countTopic() {
      let count = 0;
      this.questionsData.forEach((item) => {
          if (item.has_answer) {
              count++;
          }
      });
      this.finishSubject = count;
  }

  // 题目定位锚点
  goSubject(id: any) {
      // this.numberIndex = index;
      id = '#subject' + id;
      document.querySelector(id).scrollIntoView(true);
  }

  // 题目定位状态改变
  changeState() {
      this.titleFixed = !this.titleFixed;
  }

  // 路由守卫
  beforeRouteLeave(to: any, from: any, next: any) {
      this.mobileSave();
      clearInterval(this.mobileSaveTimer);
      next(true);
  }

  handleFileUpload(event: any, index: number) {
      this.selectedFile = event.target.files[0];
      getQinToken({}).then((res: any) => {
          const data = new FormData();
          data.append('token', res.token);
          data.append('key', res.key);
          data.append('file', this.selectedFile);
          axios.post('https://up-z2.qiniup.com', data).then(response => {
              this.imgshowlist[index] = true;
              this.questionsData[index].text_answer = 'https://c.zikaoj.com/' + res.key;
              this.questionsData[index].has_answer = true;
              this.countTopic();
          }).catch(error => {
              // 请求失败，
              console.log(error);
          });
      }).catch((error: any) => {
          console.log(error);
      });
  }
}
